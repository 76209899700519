/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import url("./assets/css/style_onboarding.css");
@import "~@angular/material/theming";
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/css/indigo-pink.css";

@import "~swiper/css";
@import "~swiper/css/free-mode";
@import "~swiper/css/scrollbar";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
    font-family: "Montserrat_SemiBold";
    font-style: "SemiBold";
    src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat_Medium";
    font-style: "Medium";
    src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat_Medium-Italic";
    font-style: "Medium-Italic";
    src: url("./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Libre_Caslon_Regular";
    font-style: "Regular";
    src: url("./assets/fonts/Libre_Caslon_Text/LibreCaslonText-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Libre_Caslon_Italic";
    font-style: "Italic";
    src: url("./assets/fonts/Libre_Caslon_Text/LibreCaslonText-Italic.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand_light";
    font-style: "Light";
    src: url("./assets/fonts/Quicksand/static/Quicksand-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand_regular";
    font-style: "Regular";
    src: url("./assets/fonts/Quicksand/static/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand_semi_bold";
    font-style: "SemiBold";
    src: url("./assets/fonts/Quicksand/static/Quicksand-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand_bold";
    font-style: "Bold";
    src: url("./assets/fonts/Quicksand/static/Quicksand-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand_medium";
    font-style: "Medium";
    src: url("./assets/fonts/Quicksand/static/Quicksand-Medium.ttf") format("truetype");
}
.QSSB {
    font-family: "Quicksand_semi_bold", "Semibold" !important;
}
.QSR {
    font-family: "Quicksand_regular", "Regular" !important;
}
.QSR18{
    font-family: 'Quicksand_regular', "Regular" !important;
    font-size: 18px !important;
}
.QSM18{
    font-family: 'Quicksand_medium', "Medium" !important;
    font-size: 18px !important;
}
.QSSB18{
    font-family: 'Quicksand_semi_bold', "Semibold" !important;
    font-size: 18px !important;
}
.QSB18{
    font-family: 'Quicksand_bold', "Bold" !important;
    font-size: 18px !important;
}
.QSL18{
    font-family: 'Quicksand_light', "Light" !important;
    font-size: 18px !important;
}
.LCR12 {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 0.75rem !important;
}

.LCR14 {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 0.875rem !important;
}

.LCR16 {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 1rem !important;
}

.LCR28 {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 1.75rem !important;
    font-weight: 600 !important;
}

.LCR36 {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 2.25rem !important;
    font-weight: 600 !important;
}

.LCI14 {
    font-family: "Libre_Caslon_Italic", "Italic" !important;
    font-size: 0.875rem !important;
}

.LCI16 {
    font-family: "Libre_Caslon_Italic", "Italic" !important;
    font-size: 1rem !important;
}
.MMSB {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
}
.MSB14 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 0.875rem !important;
}

.MSB16 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1rem !important;
}

.MSB18 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1.125rem !important;
    // opacity: 80% !important;
}

.MSB20 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1.25rem !important;
    // opacity: 80% !important;
}

.MSB24 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1.5rem !important;
}

.MSB30 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1.875rem !important;
}

.MSB36 {
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 2.25rem !important;
}

.MM6 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.375rem !important;
    line-height: normal;
}

.MM7 {
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.438rem !important;
    line-height: normal;
}

.MM8 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.5rem !important;
    line-height: normal;
}

.MM9 {
    font-family: "Montserrat_Medium", "Medium" !important;
    font-size: 0.563rem !important;
    line-height: normal;
}

.MM10 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.625rem !important;
    line-height: normal;
    display: block;
}

.MM11 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.688rem !important;
    line-height: normal;
}

.MM12 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.75rem !important;
    line-height: normal;
}

.MM13 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.813rem !important;
    line-height: normal;
}

.MM14 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 0.875rem !important;
    line-height: normal;
}

.MM16 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1rem !important;
    line-height: normal;
}

.MM18 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1.125rem !important;
    line-height: normal;
}

.MM21 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1.313rem !important;
    line-height: normal;
}

.MM22 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1.375rem !important;
    line-height: normal;
}

.MM24 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1.5rem !important;
    line-height: normal;
}

.MM30 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 1.875rem !important;
}

.MM36 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 2.25rem !important;
}

.MM48 {
    font-family: "Montserrat_Medium", "Medium" ;
    font-size: 3rem !important;
}

.MMI8 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.5rem !important;
}

.MMI9 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.563rem !important;
}

.MMI10 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.625rem !important;
}

.MMI11 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.688rem !important;
}

.MMI12 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.75rem !important;
}

.MMI14 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 0.875rem !important;
}

.MMI16 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 1rem !important;
}

.MMI18 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 1.125rem !important;
}

.MMI24 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" ;
    font-size: 1.5rem !important;
}
.logo-color{
    color: #082664;
}
.theme-color-logo{
    color: #1774bb;
    img{
        filter: invert(16%) sepia(25%) saturate(7488%) hue-rotate(307deg) brightness(90%) contrast(89%);
    }
    
}
.MMI36 {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" !important;
    font-size: 2.25rem !important;
}
.pb-20{
    padding-bottom: 20px;
}
.display-block {
    display: block;
}

.primary_cl {
    color: #1774bb !important;
}

.secondary_cl {
    color: #F5F5F5 !important;
}

//Modal Css
.modal-flex {
    &::part(content) {
        // height: 80%;
        width: 90%;
        border-radius: 10px;
    }
}

.padding-10px-right {
    padding-right: 10px;
}

.feedback-flex {
    &::part(content) {
        height: 80%;
        width: 100%;
        background: transparent !important;
        border-radius: 10px;
    }
}

.covid-info {
    width: 95% !important;
    max-width: 95vw !important;
}
.mat-info {
    width: 95% !important;
    max-width: 95vw !important;
}
.glamyo-form{
    width: 95% !important;
    max-width: 95vw !important;
}

.member-modal {
    &::part(content) {
        position: absolute;
        top: 10%;
        display: block;
        height: 80%;
        width: 100%;
        max-width: 1100px;
        border-radius: 10px;
    }
}

.profile-popover {
    &::part(content) {
        height: 65%;
        width: 90%;
        border-radius: 1.25rem;
        top: 2% !important;
        right: 0% !important;
    }
}

.dateTime-popover {
    &::part(content) {
        border-radius: 0.625rem;
        top: 55% !important;
    }
}

.dateTime-radius {
    &::part(content) {
        border-radius: 0.625rem;
    }
}

.document-flex {
    &::part(content) {
        height: 80%;
        width: 90%;
        border-radius: 10px;
    }
}
.abha-link {
    &::part(content) {
        height: 80%;
        width: 90%;
        border-radius: 10px;
    }
}

.covid-document-flex {
    &::part(content) {
        height: 80%;
        width: 90%;
        border-radius: 10px;
    }
}

.border-bottom-m {
    border-bottom: 1px solid gray;
}

.info_modal {
    &::part(content) {
        height: 40%;
        width: 90%;
        border-radius: 10px;
    }
}

.document-download {
    height: 20px;
    width: 20px;
}

.alertClass {
    &::part(content) {
        font-family: "Montserrat_Medium", "Medium" !important;
        border-radius: 10px;
        height: 35%;
        width: 80%;
    }
}

.claimSuccessClass {
    &::part(content) {
        font-family: "Montserrat_Medium", "Medium" !important;
        border-radius: 10px;
        height: 50%;
        width: 80%;
    }
}
.mine{
    position: fixed;
    right: 20px;
    top:75%;
    background: unset;
  }
  
.LCR {
    font-family: "Libre_Caslon_Regular", "Regular" !important;
    font-size: 0.75rem !important;
}

.LCRM {
    font-family: "Libre_Caslon_Regular", "Medium" !important;
}

.LCRI {
    font-family: "Libre_Caslon_Regular", "Regular-Italic" !important;
}

.LCRMI {
    font-family: "Libre_Caslon_Regular", "Medium-Italic" !important;
}

.MTM {
    font-family: "Montserrat_Medium", "Medium";
}

.MTN {
    font-family: "Montserrat_Medium", "Medium";
}

.MTMI {
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" !important;
}

//Font Styles
.font-bold {
    font-weight: bold !important;
}

.inactive_font {
    color: #808080 !important;
}

.font-black {
    color: #000000;
}

.font-blue {
    color: #00194B;
}

.cursor_pointer {
    cursor: pointer;
}

.alert_background {
    background: #fdedf2;
}

.suffix-icon {
    position: absolute;
    bottom: 15px;
    right: 10px;
    z-index: 1000;
}
.details-back-ground{
    background: linear-gradient(0deg, #FFF9F1 11.49%, rgba(255, 249, 241, 0) 78.74%);
    margin-left: -20px;
    margin-right: -15px;
    border-bottom: 1px solid #FFE8CC;
    padding-left: 10px;
    padding-right: 10px;
  }
//Input Error Handling
.error_flex {
    color: red;
    font-family: "Montserrat_Medium-Italic", "Medium-Italic" !important;
    font-size: 0.625rem !important;
    margin-bottom: 0rem !important;
}

.asterisk_req {
    color: red;
    margin-bottom: 0rem !important;
    font-family: "Montserrat_Medium", "Medium" !important;
    font-size: 1.125rem !important;
}

.id-close {
    height: 15px !important;
    width: 15px !important;
}

.border-top {
    border-top: 1px solid gray;
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.border-white-right {
    border-right: 1px solid rgb(255, 255, 255) !important;
}

//Button Stylings
.action_primary {
    background: #1774bb;
    border-radius: 10px;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.75rem;
    float: right;
    color: #fff;
    margin-bottom: 3px;
    padding: 0.5rem 1rem;
}

.action_primary:hover {
    cursor: pointer;
    background: #fff;
    color: #1774bb;
    transition: 0.3s;
    border: 2px solid #1774bb;
}

.mat-datepicker-content {
    border-radius: 0.625rem !important;
}

.action_danger {
    background: #FF5656;
    border-radius: 10px;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.75rem;
    float: right;
    color: #fff;
    border: 2px solid #FF0000;
    margin-bottom: 3px;
    padding: 0.5rem 1rem;
}

.box-shadow {
    // box-shadow: unset;
}

.text-italic {
    font-style: italic !important;
}

.download-card {
    cursor: pointer;
    background: rgba(1, 60, 219, 0.1);
    color: #1774bb;
    transition: 0.3s;
    border-radius: 20px;
    max-width: 100%;
    line-height: 20px;
}

.action_primary:disabled {
    background-color: #F5F5F5;
    color: #ffffff;
}

.action_secondary {
    background: #fff;
    border-radius: 10px;
    float: right;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.75rem;
    color: #1774bb;
    border: 2px solid #1774bb;
    margin-bottom: 3px;
    padding: 0.5rem 1rem;
}

.action_secondary_download_document {
    background: #fff;
    border-radius: 10px;
    float: right;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.75rem;
    color: #1774bb;
    margin-bottom: 3px;
    padding: 0.5rem 1rem;
}

.action_secondary:hover {
    cursor: pointer;
    background: #1774bb;
    color: #fff;
    border: 2px solid #1774bb;
    transition: 0.3s;
}

.action_secondary:disabled {
    background-color: #F5F5F5;
    color: #ffffff;
}

.upload_btn {
    background: #e6ebfb;
    border-radius: 10px;
    float: right;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.875rem;
    color: #1774bb;
    padding: 0.3rem 0.5rem;
}

.upload_btn:disabled {
    background-color: #F5F5F5;
    color: #ffffff;
}

.submit_btn {
    color: #fff;
    background-color: #3463e2;
    border-radius: 10px;
    font-family: "Montserrat_Medium", "Medium";
    font-size: 0.625rem;
    float: right;
    padding: 0.8rem;
    margin-right: 1rem;
}

.submit_btn:disabled {
    background-color: #dcdcdc;
    color: #a3a3a3;
}

.example-full-width {
    width: 100%;
}

.three-fourth-width {
    width: 75%;
}

.one-fourth-width {
    width: 75%;
}

.pt5 {
    padding-top: 5px;
}
.pt20{
    padding-top: 20px;
}
.font-blue {
    color: #00194B !important;
}

.font-black {
    color: black !important;
}

.verticaly-center-item {
    display: inline-flex;
    align-items: center;    
}

.seperator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
}

.seperator:after,
.seperator:before {
    content: "";
    display: block;
    background: grey;
    width: 30%;
    height: 1px;
    margin: 0 10px;
}

.id-card-button {
    height: 42px;
    width: 42px;
}

.qr-size {
    width: 46px;
    height: 46px;
}

.id-card-modal {
    &::part(content) {
        min-height: 537px !important;
        height: 650px;
        width: 100%;
        border-radius: 10px;
        
    }
}

.id-lower-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.pt-m-5px {
    padding-left: 5px;
}

.action_upload_document {
    background: rgba(1, 60, 219, 0.1);
    border-radius: 5px !important;
    border: unset !important;
}

.border-bottom-document {
    border-bottom: 1px solid gray;
}

.one-third-width {
    width: 25%;
}

.center-ioncard-icon {
    height: 34px;
    width: 34px;
}

.center-ioncard-icon-lg {
    height: 34px;
    width: 34px;
}

.member-is-active {
    position: relative;
    width: 75px;
    border-radius: 10px !important;
    height: 60px;
    background: #FFFFFF;
    display: flex !important;
    align-content: center !important; 
    align-items: center !important;
    .ion-align-items-center{
        ion-label{
            color: #1774bb !important;
        }
    }
}
.member-is-inactive {
    position: relative;
    width: 75px;
    border-radius: 10px !important;
    height: 60px;
    border: 1px solid #00194B;
    border-radius: 10px !important;
    display: flex !important;
    align-content: center !important; 
    align-items: center !important;
}

.quick-link-background {
    margin-top: 15px;
    border-radius: 0px 40px;
    margin-left: -10px;
    margin-right: -10px;
    background: #F5F5F5;
    border-radius: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.member-is-active:after,
.member-is-active:after {
    // z-index: 10;
    // position: absolute;
    // bottom: -9px;
    // left: 50%;
    // transform: translateX(-50%);
    // content: "";
    // width: 0;
    // height: 0;
    // border-top: solid 10px #ffffff;
    // border-left: solid 10px transparent;
    // border-right: solid 10px transparent;
}

.box-shadow-md {
    box-shadow: 0 0 12px #ababab;
    border-radius: 10px;
}

.box-shadow {
    background-color: #F5F5F5;    
}

.bank-details-border {
    border-bottom: 0.25px solid #999999 !important;
}
.member-name{
    min-width: 80px;
    min-height: 80px;
    // left: 15.01px;
    background: #FFFFFF;
  }
.margin-auto{
    margin: auto;
}
#engt-container .engt-launcher{
    bottom: 170px !important;
}
.nominee-dialog-container{
    max-height: 80vh;
}

@media (max-width: 911px) {
    .MMSB-MD {
        font-family: "Montserrat_SemiBold", "SemiBold" !important;
    }
}
.theme-color{
    background-color: #F5F5F5;
}
.dashbaord-theme-color{
    background-color: #F5F5F5;
}
.home-detail-padding{
    margin-right: 5px;
}
.service-card-padding{
    // margin-top: 45px;
}
.dashboard-theme-color{
    border-radius: 0px 0px 0px 100px;
    background-color: #F5F5F5;
}
.bottom-radius{
    background-color: #072562;
}
.dashboard-bottom-radius{
    border-radius: 0px 0px 0px 0px;
    background-color: #F5F5F5;
    padding-bottom: 50px;
}
.dashboard-top-radius{
    border-radius: 0px 0px 0px 0px;
    background-color: #FFFFFF;
    padding-top: 30px;
}
.main-color{
    background-color: #78184d;
    margin-bottom: -1px;
}
.main-color-bottom{
    margin-top: -1px;
    background-color: #1774bb;
}
.support{
    max-width: 50px;
    cursor: pointer;
  }
  .intimation-history{
    width: 95% !important;
    max-width: 95vw !important;
}
@media (min-width: 912px) {
    .mine{
        position: fixed !important;
        // bottom: 40px;
        right: 50px !important;
        top:60% !important;
        background: unset !important;
      }
      .support{
        max-width: 100px;
        cursor: pointer;
      }
    .dashboard-bottom-radius{
        border-radius: 0px 0px 0px 0px;
        background-color: #F5F5F5;
        padding-bottom: 30px;
    }
    .dashboard-top-radius{
        border-radius: 0px 0px 0px 0px;
        background-color: #F5F5F5;
    }
    .main-color{
        background-color: #77194d;
    }
    .main-color-bottom{
        background-color: #891f5b;
    }
    .service-card-padding{
        // margin-top: 15px;
    }
    .home-detail-padding{
        margin: auto;
    }
    .member-padding{
        padding-left: 80px;
        padding-right: 80px;
    }
    .member-padding{
        padding-left: 80px;
        padding-right: 80px;
    }
    body,
    html,
    .MM9 {
        font-size: 1rem !important;
    }
    .MML10 {
        font-size: 10px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML11 {
        font-size: 11px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML12 {
        font-size: 12px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML13 {
        font-size: 13px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML14 {
        font-size: 14px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML15 {
        font-size: 15px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML16 {
        font-size: 1rem !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML17 {
        font-size: 17px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML18 {
        font-size: 16px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML19 {
        font-size: 19px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML20 {
        font-size: 20px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML21 {
        font-size: 18px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML22 {
        font-size: 22px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML23 {
        font-size: 23px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML24 {
        font-size: 24px !important;
        font-family: "Montserrat_Medium", "Medium";
        line-height: 24px;
    }
    .MML25 {
        font-size: 25px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML26 {
        font-size: 26px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML27 {
        font-size: 27px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML28 {
        font-size: 28px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML29 {
        font-size: 29px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML30 {
        font-size: 30px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML32 {
        font-size: 32px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML36 {
        font-size: 36px !important;
        font-family: "Montserrat_Medium", "Medium";
    }
    .MML48 {
        font-size: 48px !important;
        font-family: "Montserrat_Medium", "Medium";
    }

    .QSML21 {
        font-family: 'Quicksand_medium', "Medium" !important;
        font-size: 21px !important;
    }

    .QSML32 {
        font-family: 'Quicksand_medium', "Medium" !important;
        font-size: 32px !important;
    }

    .one-fourth-width {
        width: 25%;
    }
    .container {
        max-width: 1230px !important;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .login-container {
        max-width: 600px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .confirm-container {
        max-width: 900px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .font-weight-normal-lg {
        font-weight: normal;
    }
    .center-ioncard-icon {
        margin-left: auto !important;
        margin-right: auto !important;
        height: 48px;
        width: 48px;
    }
    .center-ioncard-icon-lg {
        margin-left: auto !important;
        margin-right: auto !important;
        height: 48px;
        width: 48px;
    }
    .center-ioncard-alert-lg {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .member-is-active {
        // border: 2px solid blue;
        padding: 8px 10px;
        border-radius: 10px;
        width: 150px;
        display: flex!important;
        align-content: center!important;
        align-items: center!important;
        background: white;
        border-radius: 20px !important;
        .ion-align-items-center{
            ion-label{
                color: #1774bb !important;
            }
        }
    }
    .member-is-inactive {
        // border:1px solid gray;
        border: 1px solid #082664;
        // box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
        padding: 8px 10px;
        border-radius: 5px;
        // opacity: 0.5;
        width: 150px;
        display: flex!important;
        align-content: center!important;
        align-items: center!important;
        border-radius: 20px !important;
    }
    .padding-5px-right {
        padding-right: 5px;
    }
    .padding-10px-right {
        padding-right: 10px;
    }
    .MMG10 {
        display: block;
        font-size: 22px;
    }
    .border-right {
        border-right: 1px solid;
        margin-right: 5px;
    }
    .dis-block {
        display: block;
    }
    .border-right-desk {
        border-right: 1px solid;
        margin-right: 5px;
    }
    .suffix-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1000;
    }
    .border-right-lg {
        border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
        margin-right: 5px;
    }
    .border-right-lg-recent {
        border-right: 0.75px solid rgb(83, 0, 173);
        margin-right: 5px;
    }
    .desk-no-padding {
        // --padding-start: 0;
        // --padding-end: 0;
        // padding-left: 0;
        // padding-right: 0;
    }
    .quick_link {
        height: 90px !important;
        width: 90px !important;
        line-height: 90px !important;
        padding: 0px !important;
        img {
            height: 55px !important;
            width: auto;
        }
    }
    .justify-content-between {
        justify-content: space-between !important;
        >ion-col {
            flex-basis: 0 !important;
            -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
            max-width: 100% !important;
        }
    }
    .top-search {
        background-color: #eaf6fd;
        color: #000 !important;
        input {
            background: transparent !important;
            box-shadow: unset !important;
        }
    }
    .action_secondary {
        &:hover {
            svg {
                color: white !important;
                fill: white !important;
            }
        }
    }
    .bank-details-border {
        border: none;
    }
    .bank-details-padding {
        padding: 15px 25px;
    }
    ion-card {
        margin-inline: 5px;
    }
    .alert_card {
        width: 97% !important;
    }
    .announce-card {
        width: 100% !important;
    }
    .desk-margin-left-right-20 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .desk-margin-left-right-15 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .desk-margin-left-right-10 {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .desk-margin-left-right-5 {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    .desk-m-r-15 {
        margin-right: -15px;
    }
    .desk-m-r-20 {
        margin-right: -20px;
    }
    .desk-m-l-20 {
        margin-left: -20px;
    }
    .desk-m-l-15 {
        margin-left: -15px;
    }
    .announce-card {
        width: 97% !important;
    }
    .box-shadow-contact{
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        padding-top: 20px;
        padding-bottom: 40px;
      }
    .member-modal {
        &::part(content) {
            position: absolute;
            top: 10%;
            display: block;
            height: 66%;
            width: 70%;
            border-radius: 10px;
            ion-backdrop {
                --backdrop-opacity: 0.33;
            }
        }
    }
    .member-flex {
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-radius: 50%;
        // background-color: #00194B;
        color: #fff;
    }
    .border-left {
        border-left: 1px solid gray;
    }
    .border-bottom {
        border-bottom: 1px solid gray;
    }
    .ion-lg-padding {
        --padding-start: 10px;
        --padding-end: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ion-lg-padding-20 {
        --padding-start: 20px;
        --padding-end: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ion-lg-padding-15 {
        --padding-start: 15px;
        --padding-end: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .font-black-lg {
        color: #000000;
    }
    .intimation-history{
        max-width: 80vw !important;
        width: 75% !important;
        position: static;
    }
    .alertClass {
        &::part(content) {
            font-family: "Montserrat_Medium", "Medium" !important;
            border-radius: 10px;
            position: absolute;
            top: 10%;
            display: block;
            height: 50%;
            width: 50%;
        }
    }
    .claimSuccessClass {
        &::part(content) {
            font-family: "Montserrat_Medium", "Medium" !important;
            border-radius: 10px;
            position: absolute;
            top: 10%;
            display: block;
            height: 66%;
            width: 70%;
        }
    }
    .covid-info {
        max-width: 80vw !important;
        width: 75% !important;
        height: 84%;
        position: static;
    }
    .mat-info {
        max-width: 50vw !important;
        width: 30% !important;
        min-height: 30%;
        max-height: 50%;
        position: static;
    }
    .glamyo-form {
        max-width: 80vw !important;
        width: 75% !important;
        height: 84%;
        position: static;
        }
    .m-m-r-l-20 {
        --margin-start: -20px;
        --margin-end: -20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .m-m-r-lg-20 {
        margin-right: -20px;
    }
    .m-m-l-lg-15 {
        margin-left: -15px;
    }
    .m-m-r-l-15 {
        --margin-start: -15px;
        --margin-end: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .m-m-r-l-10 {
        --margin-start: -10px;
        --margin-end: -10px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .m-m-r-l-5 {
        --margin-start: -5px;
        --margin-end: -5px;
        margin-left: -5px;
        margin-right: -5px;
    }
    .p-p-r-l-15 {
        --margin-start: 15px;
        --margin-end: 15px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .claimant_card {
        margin: 5px !important;
    }
    .expand-wrapper {
        transition: max-height 0.4s ease-in-out;
        overflow: hidden;
        height: auto;
    }
    .collapsed {
        max-height: 0 !important;
    }
    .claim-list-backgroun-lg {
        background: rgba(4, 81, 154, 0.03);
    }
    .p-15-r-l {
        padding-left: 15px;
        padding-right: 15px;
    }
    .p-10-r-l {
        padding-left: 10px;
        padding-right: 10px;
    }
    .p-5-r-l {
        padding-left: 5px;
        padding-right: 5px;
    }
    .p-20-r-l {
        padding-left: 20px;
        padding-right: 20px;
    }
    .p-30-r-l {
        padding-left: 30px;
        padding-right: 30px;
    }
    .p-35-r-l {
        padding-left: 35px;
        padding-right: 35px;
    }
    .p-40-r-l {
        padding-left: 40px;
        padding-right: 40px;
    }
    .p-50-r-l {
        padding-left: 50px;
        padding-right: 50px;
    }
    .p-15-r-l {
        padding-left: 15px;
        padding-right: 15px;
    }
    .document-flex {
        &::part(content) {
            height: 80%;
            width: 1120px;
            border-radius: 10px;
        }
    }
    .abha-link {
        &::part(content) {
            height: 30%;
            width: 800px;
            border-radius: 10px;
        }
    }
    .l-h-40 {
        line-height: 40px;
    }
    .action_primary-lg {
        background: #1774bb;
        border-radius: 10px;
        font-family: "Montserrat_Medium", "Medium";
        font-size: 0.75rem;
        float: right;
        color: #fff;
        border: 2px solid #1774bb;
        margin-bottom: 3px;
        padding: 0.5rem 1rem;
    }
    .action_primary-lg:hover {
        cursor: pointer;
        background: #fff;
        color: #1774bb;
        border: 2px solid #1774bb;
        transition: 0.3s;
    }
    .action_primary-lg:disabled {
        background-color: #dcdcdc;
        color: #a3a3a3;
    }
    .lg-border-bottom-none {
        border-bottom: none !important;
    }
    .alert_background {
        background-color: #fff5f1 !important;
    }
    .MML36 {
        font-size: 36px !important;
    }
    .alert_icon {
        width: 5rem !important;
        height: 5rem !important;
    }
    .alert-button-size {
        width: 234px !important;
        height: 59px !important;
    }
    .pt-l-5px {
        padding-left: 5px !important;
    }
    .pt-6-lg {
        padding-top: 6px !important;
    }
    .pt-l-10px {
        padding-left: 10px !important;
    }
    .pt-t-15px {
        padding-top: 15px !important;
    }
    .pt-l-20px {
        padding-left: 20px !important;
    }
    .pt-l-50px {
        padding-left: 50px !important;
    }
    .pt-l-40px {
        padding-left: 40px !important;
    }
    .pt-l-30px {
        padding-left: 30px !important;
    }
    .pt-10-lg {
        padding-top: 10px !important;
    }
    .pb-5-lg {
        padding-bottom: 5px !important;
    }
    .pt-15-lg {
        padding-top: 15px !important;
    }
    .pt-20-lg {
        padding-top: 20px !important;
    }
    .pt-30-lg {
        padding-top: 30px !important;
    }
    .pt-40-lg {
        padding-top: 40px !important;
    }
    .pt-50-lg {
        padding-top: 50px !important;
    }
    .pt-60-lg {
        padding-top: 60px !important;
    }
    .pt-70-lg {
        padding-top: 70px !important;
    }
    .pt-80-lg {
        padding-top: 80px !important;
    }
    .pt-90-lg {
        padding-top: 90px !important;
    }
    .mt-m-25-lg {
        margin-top: -25px !important;
    }
    .mt-m-10-lg {
        margin-top: -10px !important;
    }
    .pt-35-lg{
        padding-top: 35px !important;
    }
    .mt-m-20-lg {
        margin-top: -20px !important;
    }
    .mt-t-20 {
        margin-top: 20px !important;
    }
    .mt-t-30 {
        margin-top: 30px !important;
    }
    .mt-t-25 {
        margin-top: 25px !important;
    }
    .mt-t-100 {
        margin-top: 100px !important;
    }
    .mt-t-50 {
        margin-top: 50px !important;
    }
    .mt-t-40 {
        margin-top: 40px !important;
    }
    .mt-b-40 {
        margin-bottom: 40px !important;
    }

    .pb-l-20 {
        padding-bottom: 20px !important;
    }
    .p-b-lg-30 {
        padding-bottom: 30px !important;
    }
    .p-b-lg-35 {
        padding-bottom: 35px !important;
    }
    .p-b-lg-40 {
        padding-bottom: 40px !important;
    }
    .p-b-lg-50 {
        padding-bottom: 50px !important;
    }
    .p-b-lg-60 {
        padding-bottom: 60px !important;
    }
    .m-p-r-25 {
        margin-right: 25px !important;
    }
    .m-p-l-20 {
        margin-left: 20px !important;
    }
    .bank-image {
        height: 20px;
        width: 20px;
    }
    .id-card-modal .modal-wrapper {
        position: absolute;
        top: 10%;
        display: block;
        height: 820px;
        width: 80%;
        max-width: 700px !important;
        border-radius: 10px;
    }
    .id-card-container {
        max-width: 840px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .text-italic-lg {
        font-style: italic !important;
    }
    .hr-lg {
        border-bottom: 1px solid gray !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
    .half-width-lg {
        width: 50%;
    }
    .one-fourth-width-lg {
        width: 25%;
    }
    .three-fourth-width-lg {
        width: 75% !important;
    }
    .thirty-width-lg {
        width: 30% !important;
    }
    .lg-no-padding-right-input {
        --padding-end: 0px;
        --inner-padding-end: 0px;
        // here other custom CSS from documentation
    }
    .covid-border {
        border: 1px solid;
    }
    .member-card-lg {
        height: 101px !important;
        width: 250px !important;
    }
    .action_primary {
        border-radius: 0.938 !important;
        // max-width: 200px;
        height: 48px;
    }

    .action_secondary {
        border-radius: 10px !important;
        min-width: 150px;
        min-height: 48px;
        // max-width: 200px;
    }
    .action_secondary_confirm {
        border-radius: 10px !important;
        height: 48px !important;
    }
    .action_primary_confirm {
        border-radius: 10px !important;
        height: 48px !important;
    }
    .btn_secondary {
        border-radius: 24px !important;
    }
    .action_secondary {
        border-radius: 10px !important;
        height: 50px;
        font-size: 21px !important;
    }
    .btn_secondary {
        border-radius: 24px !important;
    }
    .btn_primary {
        border-radius: 24px !important;
        height: 50px;
    }
    .padding-30p-lg {
        padding: 30px !important;
    }
    .lg-w-h-20 {
        height: 20px;
        width: 20px;
    }
    .upload_rounded {
        background: #1774bb;
        border-radius: 100%;
        font-family: "Montserrat_Medium", "Medium";
        font-size: 2rem !important;
        color: #fff;
        padding: 10px 10px 2px 11px !important;
    }
    .padding-70p-lg {
        padding-top: 70px !important;
    }
    .action_delete {
        border-radius: 24px !important;
    }
    .verticaly-center-item-lg {
        display: inline-flex;
        align-items: center;
    }
    .modal-flex {
        &::part(content) {
            // height: 60%;
            width: 60%;
            border-radius: 10px;
        }
    }
    .feedback-flex {
        &::part(content) {
            height: 60%;
            width: 60%;
            background: transparent !important;
            border-radius: 10px;
            box-shadow: none;
        }
    }
    .claim-doxument-padding-lg {
        padding: 0px 60px;
    }
    .box-shadow {
        // box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
        //   rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
        //box-shadow: 0 0 12px #ababab;
        border-radius: 30px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(8, 39, 102, 0.15);
    }
    .box-shadow-member {
        box-shadow: 0 0 12px #ababab !important;
        border-radius: 10px !important;
    }
    .desk-dashboard-details{
        padding:20px 70px 20px 70px;
    }
    .download-card {
        cursor: pointer;
        background: rgba(1, 60, 219, 0.1);
        color: #1774bb;
        transition: 0.3s;
        border-radius: 20px;
        max-width: 50%;
        line-height: 20px;
        margin-left: 45px;
        height: 45px;
        font-size: 18px !important;
    }
    .id-close {
        height: 25px !important;
        width: 25px !important;
        margin-right: 45px;
    }
    .action_upload_document-lg {
        background: rgba(1, 60, 219, 0.1) !important;
        border-radius: 5px !important;
        border: unset !important;
    }
    .lg-font-weight-500 {
        font-weight: 500 !important;
    }
    .download-document-button {
        min-height: 75px;
        min-width: 107px;
    }
    .verify-otp-button {
        min-width: 120px;
        max-width: 150px;
    }
    .request-otp-button {
        background: rgba(1, 60, 219, 0.1);
        border-radius: 0px 10px 10px 0px;
        font-family: "Montserrat_Medium", "Medium";
        font-size: 0.75rem;
        float: right;
        color: blue;
        border-top: 1px solid blue;
        border-right: 1px solid blue;
        border-bottom: 1px solid blue;
        margin-bottom: 0px;
        padding: 0.5rem 1rem;
    }
    .registered-mobile-text {
        border-radius: 10px 0px 0px 10px;
    }
    .lg-float-left {
        float: left !important;
    }
    .lg-float-right {
        float: right !important;
    }
    .documentsField-covid {
        border: 1px solid #000 !important;
    }
    .id-card-button {
        height: 76px;
        width: 76px;
    }
    .qr-size {
        width: 80px;
        height: 80px;
    }
    .document-download {
        height: 30px;
        width: 30px;
    }
    .action_secondary_download_document {
        background: #fff;
        border-radius: 10px;
        float: right;
        font-family: "Montserrat_Medium", "Medium";
        font-size: 0.75rem;
        color: #1774bb;
        border: 2px solid #1774bb !important;
        margin-bottom: 3px;
        padding: 0.5rem 1rem;
    }
    .enrollment-font-color {
        color: #00194B !important;
    }
    .gamification-icon {
        width: 54px !important;
    }
    .gamificationMobile {
        border: 1px solid #1774bb !important;
        padding: 0rem 0.625rem !important;
        font-family: "Montserrat_Medium", "Medium" !important;
        font-size: 0.875rem !important;
        background-color: #f2f5fd;
        border-radius: 10px;
    }
    .start-enrollment-button {
        width: 200px !important;
    }
    .confirm-enrollment-button {
        width: 250px !important;
    }
    .profile_headline_lg {
        font-family: "Libre_Caslon_Regular", "Regular" !important;
        font-size: 1.5rem !important;
        color: #000000;
    }
    .profile_headline_lg span {
        height: 2px;
        width: 8rem;
        position: absolute;
        background: #000000;
        left: 0.5rem;
        border-radius: 0.5rem;
        margin-top: 2rem;
    }
    .LCLG24 {
        font-family: "Libre_Caslon_Regular", "Regular" !important;
        font-size: 1.75rem !important;
    }
    .button-lg-150 {
        width: 150px;
        height: 48px;
    }
    .border-right-blue {
        border-right: 1px solid #00194B;
    }
    .center-lg {
        margin: 0;
        position: absolute;
        left: 46%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, 0%);
    }
    .heading-sum {
        padding-top: 33px;
        padding-bottom: 33px;
    }
    .action_secondary_center {
        background: #fff;
        border-radius: 24px;
        font-family: "Montserrat_Medium", "Medium";
        font-size: 0.75rem;
        color: #1774bb;
        border: 2px solid #1774bb;
        margin-bottom: 3px;
        padding: 0.5rem 1rem;
    }
    .id-card-modal {
        &::part(content) {
            position: absolute;
            top: 5% !important;
            display: block;
            height: 860px;
            width: 100%;
            max-width: 700px !important;
            border-radius: 10px;
        }
    }
    .action_primary:disabled {
        background-color: #F5F5F5;
        color: #ffffff;
        border: unset;
        height: 48px;
        min-width: 150px;
        font-size: 21px;
        // max-width: 200px;
    }
    .lg-alert-title {
        padding-top: 8px;
        padding-bottom: 0px;
    }
    .lg-alert-subtitle {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: -5px;
    }
    .alert_card {
        border-radius: 10px;
    }
    .alert_bar {
        width: 7px !important;
    }
    .capitalize {
        text-transform: capitalize;
    }
    .lg-member-card {
        height: 120px;
    }
    .MMSB {
        font-family: "Montserrat_SemiBold", "SemiBold" !important;
    }
    .font-libre-lg {
        font-family: Libre Caslon Text !important;
        font-style: semi_bold;
    }
    .headingTextLg {
        font-family: Libre Caslon Text !important;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        align-items: center;
        letter-spacing: -0.015em;
        color: #00194B;
    }
    .title_span {
        height: 3px;
        background-color: #00194B;
        border-radius: 10px;
        width: 7rem !important;
        margin-top: 0.7rem;
    }
    .m-t-m-55 {
        margin-top: -55px !important;
    }
    .action_card {
        min-height: 100px;
    }
    .login-tile {
        background: #fffbf6;
        padding-right: 30px;
        padding-left: 31px;
        border-radius: 20px;
    }
    .member-is-active {
        position: relative;
        width: 150px !important;
        // box-shadow: 0 0 12px #ababab !important;
        box-shadow: 0px 2px 10px rgba(8, 38, 100, 0.15) !important;
        border-radius: 20px !important;
        height: 120px;
    }
    .member-is-inactive {
        width: 150px;
        height: 120px;
    }
    // .login-left {
    //     background: #fffbf6;
    // }
    .m-l-r-25 {
        margin-left: 25px;
        margin-right: 25px;
        --margin-left: 25px;
        --margin-right: 25px;
    }
    .m-l-r-20 {
        margin-left: 20px;
        margin-right: 20px;
        --margin-left: 20px;
        --margin-right: 20px;
    }
    .covid-document-flex {
        &::part(content) {
            height: 80%;
            width: 90%;
            max-width: 1150px !important;
            border-radius: 10px;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    }
    .link_card {
        cursor: pointer;
        border-radius: 0.625rem;
        margin: 0rem 0rem 0.5rem 0rem !important;
        box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
        width: 480px !important;
        height: 100px !important;
    }
    .margin-lg-save {
        margin-left: 25px !important;
    }
    @-webkit-keyframes autofill {
        0%,
        100% {
            color: #666;
            background: transparent;
        }
    }
    input:-webkit-autofill {
        -webkit-animation-delay: 1s;
        /* Safari support - any positive time runs instantly */
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
    .member-name{
        // width: 150px;
        min-width: 150px !important;
        min-height: 120px;
      }  
      .quick-link-background {
        background: #FFFFFF;
        padding-top: 50px;
        padding-bottom: 15px;
        box-shadow: 0px 2px 10px rgba(8, 39, 102, 0.15);
        border-radius: 0px 40px;
        margin-left: -10px;
        margin-right: -10px;
        height: 180px;
    }
    .title{
        height: 90px;
    }
    .member-full-list {
        margin-left: -12px; 
        margin-right: -12px;
        padding-left: 80px;
        padding-right: 80px;
      }
}

.native-input.sc-ion-input-md:-webkit-autofill {
    background-color: transparent !important;
}

.stepStyle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #000000;
    text-decoration: underline;
    text-underline-position: under;
    opacity: 0.6;
}

.stepStyle1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: #000000;
    text-decoration: underline;
    text-underline-position: under;
}

.headingText {
    font-family: Libre Caslon Text;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #00194B;
}

.headingText1 {
    font-family: Libre Caslon Text;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #00194B;
}

.font-libre {
    font-family: Libre Caslon Text;
    font-style: normal;
}

.subHeadingText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 29px;
    align-items: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px !important;
    color: #000000;
}

.titleDesc {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 22px;
    align-items: center;
    letter-spacing: -0.015em;
    padding-top: 0.3rem;
    color: #00194B;
}

@media (min-width: 912px) {
    .titleDesc {
        font-size: 18px !important;
    }
}

::ng-deep.mat-form-field.mat-form-field-should-float.fieldLabel {
    color: #00194B !important;
}

.fieldLabel {
    font-family: Montserrat !important;
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    align-items: center !important;
    letter-spacing: -0.015em !important;
}

.titleDescB {
    font-family: Montserrat !important;
    font-style: italic !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    align-items: center !important;
    letter-spacing: -0.015em !important;
    color: #000 !important;
}

.buttonTernaryBlue {
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 25px !important;
    width: 80px !important;
    height: 40px !important;
    color: #1774bb !important;
    background-color: white !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 14px !important;
    cursor: pointer;
}

.buttonSecondaryTBlue{
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 10px !important;
    color: #1774bb !important;
    background-color: white !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1rem !important;
    cursor: pointer;
    // width: 100%;
}

.buttonBackgroundBlue {
    background: rgba(1, 60, 219, 0.1) !important;
    border: 1px solid #1774bb !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 26px !important;
    /* identical to box height */
    text-align: center !important;
    letter-spacing: -0.015em !important;
    color: #1774bb !important;
}

.buttonSecondaryBlue {
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 25px !important;
    width: 150px !important;
    height: 48px !important;
    color: #1774bb !important;
    background-color: white !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1rem !important;
    cursor: pointer;
}

.buttonPrimaryBlue {
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 25px !important;
    width: 150px !important;
    height: 48px !important;
    color: white !important;
    background-color: #1774bb !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 1rem !important;
}

.buttonSmallPrimaryBlue {
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 25px !important;
    min-width: 120px !important;
    height: 40px !important;
    color: white !important;
    background-color: #1774bb !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 14px !important;
    margin-left: 2rem !important;
}

.buttonSmallPrimaryBlue:disabled {
    border-color: #ccd8f8 !important;
    background-color: #ccd8f8 !important;
}

.buttonSmallSecondaryBlue {
    border: 2px !important;
    border-style: solid !important;
    border-color: #1774bb !important;
    border-radius: 25px !important;
    min-width: 120px !important;
    height: 40px !important;
    color: #1774bb !important;
    background-color: white !important;
    font-family: "Montserrat_SemiBold", "SemiBold" !important;
    font-size: 14px !important;
}

.totalPremiumTab {
    background: #1774bb;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    align-items: center;
    letter-spacing: -0.015em;
    padding: 1rem 1rem;
    color: #ffffff;
    margin-bottom: 1rem;
}

.textTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    margin-bottom: 0.2rem !important;
    color: #1774bb;
}

.textContent {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    background-color: #1774bb !important;
}

::ng-deep .mat-radio-inner-circle {
    background-color: #1774bb !important;
}

.memberCoverBlueBox {
    background: #1774bb;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin: 1rem !important;
    margin-left: 0px !important;
    color: white;
    padding-left: 1rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.memberCoverBox {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin: 1rem !important;
    margin-left: 0px !important;
}

.memberCoverBoxText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 29px;
    align-items: center;
    letter-spacing: -0.015em;
    margin: 0px !important;
    padding: 0.5rem !important;
    color: #000000;
}

.boxText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    letter-spacing: -0.015em;
    color: #ffffff;
}

.boxTextDesc {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    color: #ffffff;
}

.memberCoverBoxSubText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 29px;
    align-items: center;
    letter-spacing: -0.015em;
    margin: 0px !important;
    padding: 0.5rem !important;
    color: #000000;
}

.activate {
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    border: 1px solid #42deb1;
    background: rgba(66, 222, 177, 0.2);
    color: #42deb1;
    margin: 0.4rem !important;
    padding: 0.5rem;
    text-align: center;
}

.deactivate {
    background: rgba(248, 44, 63, 0.05);
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    border: 1px solid #f82c3f;
    color: #f82c3f;
    margin: 0.4rem !important;
    padding: 0.5rem;
    text-align: center;
}

.editBtn {
    background: rgba(1, 60, 219, 0.05) !important;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    color: #1774bb !important;
    margin: 0.4rem !important;
    padding: 0.5rem;
    text-align: center;
}

.mat-form-field-required-marker {
    color: red;
}

.topup_card {
    background: #ffffff;
    border: 3px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
}

.topupActive {
    border: 3px solid #42deb1;
    background: rgba(66, 222, 177, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.float-right {
    float: right;
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
    --backdrop-opacity: 0.5;
    --box-shadow: none !important;
}

.align-middle {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.secondaryBtn {
    background: rgba(1, 60, 219, 0.08);
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding: 1rem;
    color: #00194B;
}

.plr3 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.baseMembersCovered {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    padding: 1rem 2rem;
    margin-right: 1rem;
    color: #000000;
    background: #f1f1f1;
    border: 1px solid #5f5f5f;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.baseMembersCoveredEnroll {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    align-items: center;
    letter-spacing: -0.015em;
    padding: 1rem 2rem;
    margin-right: 1rem;
    color: #000000;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.192);
    border-radius: 10px;
    border: 0.25px solid #00000057;
}

.baseMembersCoveredEnrollActive {
    border: 3px solid #42deb1;
    background: rgba(66, 222, 177, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

ion-backdrop {
    opacity: 0.6 !important;
}

.alert-title {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 36px !important;
    line-height: 44px !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: -0.015em !important;
    color: #1774bb !important;
}

.alert-sub-title {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: -0.015em !important;
    color: #000000 !important;
}

.alert-message {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: -0.015em !important;
    color: #000000 !important;
}

.alert-wrapper {
    max-width: 100vw !important;
    max-height: 90vh !important;
    margin: 1rem !important;
    padding: 1rem !important;
}

.alert-button-inner {
    justify-content: center !important;
}

.alert-button-group {
    justify-content: center !important;
}


@media (max-width: 768px) {
    .select_flex {
      border-radius: 0.625rem;
      margin-right: 5rem;
    }
  }

  .app-overlay-container {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .app-overlay-container:empty {
    display: none;
  }

.zIndex2 {
    z-index: 2 !important;
}
.custom-dialog-container{
    width: 90vh;
    max-width: 750px !important;
}
.custom-dialog-container .mat-dialog-container {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
 }
 .custom-dialog-container-custodian{
    width: 90vh;
    max-width: 750px !important;
}
.custom-dialog-container-custodian .mat-dialog-container {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
 }
 .errorMsg{
    color: red;
    font-size: 10px;
 }
 .show-anchor{
    cursor: pointer;
 }

 .row.memberCoverBox{
    margin:0;
    align-items: center;
    margin-top:10px;
  }
  .memberCoverBox{
  border:2px solid #e3e3e3;
  padding:0px 20px;
  min-height: 70px;
  }
  .memberCoverBlock .memberCoverBox h4{
  display:inline-block;
  font-size:14px;
  color:#000;
  margin-bottom: 0;
  }
  .memberCoverBlock .memberCoverBox .col-md-8{
  padding:0;
  }
  .memberCoverBlock .memberCoverBox a.edit , .memberCoverBlock .memberCoverBox a.deactivate{
  font-size:14px;
  margin:0 6px;
  text-decoration: none;
  
  }
  .memberCoverBlock .memberCoverBox a.edit {
  color:#007bff
  }
  .memberCoverBlock .memberCoverBox a.deactivate{
    color:red;
  }
  
 ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}